import thetrumpturdslogo from './images/thetrumpturdslogo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <img src={thetrumpturdslogo} className="thetrumpturdslogo" alt="The Trumpturds" />
    </div>
  );
}

export default App;
